import * as React from "react"
import Typewriter from 'typewriter-effect';
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Michael Boyko | Web Developer Portfolio" description="I am an accomplished front end web developer" />
    <h1>
    <Typewriter
      onInit={(typewriter) => {
        typewriter.typeString('coming.soon()')
          .pauseFor(2500)
          .deleteAll()
          .start();
      }}
      options={{
        autoStart: true,
        loop: true,
      }}
    />
    </h1>
  </Layout>
)

export default IndexPage
